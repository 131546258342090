import React from "react"
import { Link } from "gatsby"
import { useMetadata } from "../hooks/usemetadata"
import Image from "gatsby-image"

export default () => {
  const metadata = useMetadata()
  const credits =
    metadata.credits.length < 10
      ? metadata.credits
          .concat(new Array(10).fill(metadata.credits[0]))
          .sort(function (a, b) {
            return 0.5 - Math.random()
          })
      : metadata.credits.sort(function (a, b) {
          return 0.5 - Math.random()
        })
  return (
    <div className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll bg-light pb-4">
      <div
        className="dzsparallaxer--target"
        style={{ opacity: 1, visibility: "visible" }}
      >
        <div className="container-fluid px-lg-6">
          <div className="row justify-content-center">
            <div className="col-3 my-8">
              <div className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[0].picture.asset.fluid}
                  alt={credits[0].name}
                />
              </div>
            </div>
            <div className="col-3 mb-4 mt-2">
              <div className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[1].picture.asset.fluid}
                  alt={credits[1].name}
                />
              </div>
            </div>
            <div className="col-3 my-8">
              <div className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[8].picture.asset.fluid}
                  alt={credits[8].name}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-between row-2">
            <div className="col-3 mb-4">
              <div className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4">
                <Image
                  className="avatar-img"
                  fluid={credits[9].picture.asset.fluid}
                  alt={credits[9].name}
                />
              </div>
            </div>
            <div className="col-3 my-4">
              <div className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[2].picture.asset.fluid}
                  alt={credits[2].name}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-3 offset-1 my-4">
              <div className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[3].picture.asset.fluid}
                  alt={credits[3].name}
                />
              </div>
            </div>
            <div className="col-3 offset-2 my-4">
              <div className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[4].picture.asset.fluid}
                  alt={credits[4].name}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-between row-4">
            <div className="col-4">
              <div className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[5].picture.asset.fluid}
                  alt={credits[5].name}
                />
              </div>
            </div>
            <div className="col-4 mt-n2">
              <div className="d-none d-sm-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[6].picture.asset.fluid}
                  alt={credits[6].name}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto">
                <Image
                  className="avatar-img"
                  fluid={credits[7].picture.asset.fluid}
                  alt={credits[7].name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-3 space-sm-4 position-relative z-index-2">
        <div className="w-md-80 w-lg-50 text-center mx-md-auto">
          <span className="d-block small font-weight-bold text-cap mb-2">
            Credits
          </span>
          <h2 className="font-weight-normal ">
            Rajiv is the only person ever to perform standup comedy on all 7
            continents.
          </h2>
          <Link
            className="btn btn-primary btn-pill btn-wide transition-3d-hover"
            to="/hire"
            target="_blank"
          >
            Hire Rajiv
          </Link>
        </div>
      </div>
    </div>
  )
}
