import React from "react"
import Layout from "../components/layout/layout"
import Testimonial from "../components/testimonial"
import Hero from "../components/front_hero"
import Credits from "../components/credits"
import FeaturedVideos from "../components/featured_videos"
import UpcomingShows from "../components/upcoming_shows"
import Instagram from "../components/instagram"
import { Helmet } from "react-helmet"
import Overlay from "../components/Overlay"
import { useState } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

export default data => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  return (
  <Layout>
    <Helmet title={`Rajiv Satyal`} />
      {/* <Overlay isOpen={isOpen} onClose={toggleOverlay}>
        <div class="row">
          <div className="column">
            <div className="text-center">
              Watch Rajiv!
            </div>
            <div className="text-center">
              <a href="http://drybarcomedy.com/rajivs" target="_blank">
                <img src="/shortnotice.jpeg" alt="Rajiv Satyal" />
              </a>
            </div>
          </div>
          <div className="column">
            <div className="text-center">
              See Rajiv in NYC!
            </div>
            <div className="text-center">
              <a href="https://www.eventbrite.com/e/rajiv-satyal-standup-comedy-special-screening-new-york-tickets-852603499017?aff=oddtdtcreator" target="_blank">
                <img src="/seerajiv.jpg" alt="Rajiv Satyal" />
              </a>
            </div>
          </div>
        </div>
      </Overlay> */}
    <Hero data={data.data.sanityPages} />
    <Testimonial />
    <Credits />
    <FeaturedVideos />
    <UpcomingShows frontPage={true} />
    <Instagram />
  </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    sanityPages(slug: { current: { eq: "index" } }) {
      id
      hero_subtitle
      hero_title
      hero_video
      title
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            src
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
