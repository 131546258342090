import React from "react"
import Image from "gatsby-image"
import { useMetadata } from "../hooks/usemetadata"

export default () => {
  const metadata = useMetadata()

  // const { allInstaNode } = useStaticQuery(graphql`
  //   query InstagramQuery {
  //     allInstaNode(sort: { fields: timestamp, order: DESC }) {
  //       edges {
  //         node {
  //           id
  //           caption
  //           comments
  //           timestamp
  //           likes
  //           mediaType
  //           localFile {
  //             childImageSharp {
  //               fluid(maxWidth: 350) {
  //                 ...GatsbyImageSharpFluid
  //               }
  //             }
  //           }
  //           dimensions {
  //             width
  //             height
  //           }
  //           preview
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="container">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span className="d-block small font-weight-bold text-cap mb-2">
          INSTAGRAM
        </span>
        <h2>Follow along @ home</h2>
      </div>
      <div className="row mx-n2">
        {metadata.instagram.map((item, key) => (
          <div className="col-12 col-md-3 px-2 mb-3" key={key}>
            <a
              href={item.url}
              target="_blank"
              className="h-250rem"
              rel="noopener noreferrer"
              style={{ display: "block", width: "100%" }}
            >
              <Image fluid={item.image.asset.fluid} alt={item.caption} />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
