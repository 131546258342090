import React, { useState } from "react"
import { useMetadata } from "../hooks/usemetadata"
import FsLightbox from "fslightbox-react"
import BackgroundImage from "gatsby-background-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Hero(props) {
  const [toggler, setToggler] = useState(false)
  const metadata = useMetadata()
  return (
    <BackgroundImage
      Tag="section"
      className="d-md-flex min-vh-md-100 bg-img-hero mx-0"
      fluid={props.data.hero_bg.asset.fluid}
      backgroundColor={`#040e18`}
    >
      <FsLightbox toggler={toggler} sources={[props.data.hero_video]} />
      <div className="container d-flex flex-column  justify-content-center align-items-md-center flex-md-column min-vh-md-100 text-center">
        <h1
          className="display-1 text-white"
          style={{ textShadow: "2px 2px #000" }}
        >
          {props.data.hero_title}
        </h1>
        <button
          className="display-1 hero-play"
          onClick={() => setToggler(!toggler)}
          aria-label="Play Video"
        >
          <FontAwesomeIcon
            icon={["far", "play-circle"]}
            className="text-white"
          />
        </button>
        <div className="row justify-content-between text-center mt-med-10 mr-1 hero-clients">
          {metadata.clients.map((item, key) => (
            <div className="col-2 col-lg-2 mb-5 mb-lg-0 " key={key}>
              <div className="  mx-md-3">
                <img
                  src={item.picture.asset.fluid.src}
                  className="max-w-7rem max-w-md-13rem mx-auto"
                  alt={item.name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </BackgroundImage>
  )
}
