import React from "react"
import Slider from "react-slick"
import { useMetadata } from "../hooks/usemetadata"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default () => {
  const metadata = useMetadata()
  const settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    arrows: false,
  }
  return (
    <div className="container space-2 space-lg-3">
      <div className="w-md-100 w-lg-100 mx-md-auto">
        <figure className="mx-auto text-center mb-5">
          <FontAwesomeIcon
            icon={["fas", "quote-left"]}
            className="text-center text-primary display-1"
          />
        </figure>
        <Slider {...settings}>
          {metadata.testimonial.map((item, key) => (
            <div className="js-slide" key={key}>
              <div className="text-center mb-5">
                <blockquote
                  className="h2 text-lh-lg"
                  style={{ fontWeight: 400 }}
                >
                  {item.quote}
                </blockquote>
              </div>
              <div className="d-flex justify-content-center align-items-center w-lg-50 mx-auto">
                {/* <div className="avatar avatar-circle">
                  <Img
                    fluid={item.picture.asset.fluid}
                    className="avatar-img"
                  />
                </div> */}
                <div>
                  <h5 className="mb-0" style={{ color: "#8c98a4" }}>
                    {item.name}
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
