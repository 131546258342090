import React, { useState } from "react"
import FsLightbox from "fslightbox-react"
import Img from "gatsby-image"
import { useVideos } from "../hooks/usevideos"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default props => {
  const [lb, setLb] = useState({
    toggler: false,
    slide: 1,
  })

  function openLightboxOnSlide(number) {
    setLb({
      toggler: !lb.toggler,
      slide: number,
    })
  }

  let data = useVideos()

  return (
    <div className="container space-2">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span className="d-block small font-weight-bold text-cap mb-2">
          Featured Videos
        </span>
        <h2>Watch Rajiv</h2>
      </div>
      <FsLightbox
        toggler={lb.toggler}
        slide={lb.slide}
        sources={data.featuredVideos.nodes.map((item, key) => {
          return item.url
        })}
      />
      <div className="row">
        {data.featuredVideos.nodes.map((item, key) => (
          <div className="col-md-4 col-sm-6 pb-4 video" key={key}>
            <div
              className="card h-100"
              onClick={() => openLightboxOnSlide(key + 1)}
              onKeyDown={() => openLightboxOnSlide(key + 1)}
              role="button"
              tabIndex={0}
            >
              <Img
                className="card-img-top video-card"
                fluid={item.cover_image.asset.fluid}
                alt={item.title}
              />
              <FontAwesomeIcon
                icon={["far", "play-circle"]}
                className="text-white thumb h1"
              />
              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
